<template>
  <el-main class="simple1-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        做公益 運動汗水不白流
      </div>
      <p>2023年2月 </p>
      <div class="image-container">
        <img :src="image" class="image" alt="家裡就是健身房｜激發自我宅動力">
        <p class="image_desc">家裡就是健身房｜激發自我宅動力</p>
        <p class="image_desc">圖片為經濟日報照片</p>
        <p class="image_desc">(圖片提供：本計畫)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">公益目標</div>
        <div class="content-desc">
          根據110年教育部體育署針對台灣運動現況的調查結果顯示：110年因應疫情影響居家運動比例較109年提升6.3%，共佔15.3%，由此顯現在家運動比例大幅增加，透過活動希望能讓更多民眾執行居家運動，推動更多創新與數據利他價值應用。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動 </div>
        <div class="content-desc">
          本計畫協手優力勁聯、台灣運動健身產業協會一同辦理「全民運動會：市民動滋動˙健康享好禮」。 
        </div>
        <div class="content-desc">
          於民國111年11月1日至12月31日為期2個月，共完成累計27,229人次及168,161筆運動生理數據，其中包含運動時段、運動時間、課程名稱、性別、年齡等運動生理數據，完整數據留存原始資料於AR動滋動XUniigymAPP中，並已與運動數據公益平台串接。  
        </div>
      </div>
      <div class="image-container">
        <img :src="image_1_2" class="image" alt="社會在走，體能要有" aria-hidden="true">
        <p class="image_desc">AR動滋動XUniigym 裡面有不同的運動課程，簡單使用20~30分鐘的時間做一場高強度間歇運動</p>
        <p class="image_desc">(圖片提供：優力勁聯)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">數據公益模式</div>
        <div class="content-desc">
          透過AR動滋動XUniigymAPP收集了居家運動的數據，包括課程名稱、運動時間、性別、年齡…等數據，可透過此數據來了解不同年齡層和性別喜好的課程內容以及運動時間，用以設計相對應的運動處方課程或是線上遊戲。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">公益展望</div>
        <div class="content-desc">
          透過分析用戶運動型態，以協助運動教練規劃課程；後續可探討特殊、高齡族群的運動習慣，配合醫療單位、長照機構發展復健、預防失智、延緩失能運動處方，以實踐數據應用於健康促進與活躍老化之目標。
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11101',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/sample_1.png'),
      image_1_2: require('@/assets/images/sample/sample_1-2.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple1-container{
  position: relative;  
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
